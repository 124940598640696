<template>
  <a-tree-select
    v-model="inputValue"
    v-bind="$attrs"
    v-on="$listeners"
    placeholder="请选择文件夹"
    :tree-data="folderList"
    :replaceFields="{ title: 'name' }"
  >
  </a-tree-select>
</template>

<script>
import api from '@/pages/app/xhsAgency/xhsPicStore/api.js';

export default {
  props: {
    value: { default: () => [] },
  },
  data() {
    return {
      inputValue: undefined,
      folderList: [],
      tagListFlat: [],
    };
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
  computed: {
    allFolderList() {
      return this.flattenTree(this.folderList);
    },
  },
  methods: {
    async getFolderList(principalIds) {
      this.folderList = [];
      const params = {
        principalIds,
        isDir: 1,
      };
      const { code, data, message } = await api.getPictureFolderTree(params);
      if (code === 200) {
        this.folderList = data[0]?.treeList?.map(this.initTreeData) || [];
      } else {
        this.$message.error(`${message}`);
      }
    },
    clearList() {
      this.folderList = [];
    },
    flattenTree(tree) {
      const result = [];
      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }
      for (const item of tree) {
        traverse(item);
      }
      return result;
    },
    initTreeData(data) {
      if (data.children?.length > 0) {
        data.children = data.children.map(this.initTreeData);
      }
      return {
        ...data,
        value: data.id,
        key: data.id,
      };
    },
  },
  created() {
    this.inputValue = this.value;
  },
};
</script>

<style scoped lang="scss"></style>
